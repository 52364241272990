@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css"; */
@import 'react-responsive-carousel/lib/styles/carousel.min.css';


body {
  font-family: 'Poppins', sans-serif;
  /* background: linear-gradient(90deg, #370249, #320D3A, #46131F); */
  background-color: #03050d;
  /* background-image: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%); */
  /* background-image: linear-gradient(90deg, rgba(189, 36, 223, 0.5) 0%, rgba(45, 106, 222, 0.5) 97.15%); */
  background-blend-mode: overlay;
}

html {
  scroll-behavior: smooth !important;
}
.navbar {
  /* background-color: #1330A7; */
  /* background: linear-gradient(45deg, #0C1648, #182B91, #03143F); */
  background: linear-gradient(45deg, #0C0B27, #1B164F, #1C1A58, #10184B, #091239, #020A1E);
  /* background: radial-gradient(circle, #182B91, #03143F); */
  /* background: linear-gradient(to bottom, #1330A7, transparent); */
}

html:not([data-scroll="0"]) .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-image: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%); */
  background-color: linear-gradient(45deg, #0C0B27, #1B164F, #1C1A58, #10184B, #091239, #020A1E);
  /* background-color: #252525; */
  /* background-color: red; */
  background-blend-mode: overlay;
  opacity: 1.9;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
  /* transition: 0.4s linear; */
  z-index: 40;
}

.navbutton {
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  border-radius: 10px;
}

.navbutton:hover {
  background: linear-gradient(90deg, #2D6ADE 0%, #BD24DF 97.15%);
}

.arrowOne {
  position: absolute;
  bottom: 37%;
  left: 0px;
  background-image: url('../public/images/Banner/arrowOne.svg');
  width: 105px;
  height: 59px;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowTwo {
  position: absolute;
  top: 20%;
  left: 17%;
  /* background-image: url('../public/images/Banner/arrowTwo.svg'); */
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowThree {
  position: absolute;
  background-image: url('../public/images/Banner/arrowThree.svg');
  top: 32%;
  left: 53%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowFour {
  position: absolute;
  background-image: url('../public/images/Banner/arrowFour.svg');
  top: 48%;
  left: 46%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowFive {
  position: absolute;
  background-image: url('../public/images/Banner/arrowFive.svg');
  top: 61%;
  left: 53%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowSix {
  position: absolute;
  background-image: url('../public/images/Banner/arrowSix.svg');
  top: 39%;
  left: 80%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowSeven {
  position: absolute;
  background-image: url('../public/images/Banner/arrowSeven.svg');
  top: 57%;
  left: 79%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.arrowEight {
  position: absolute;
  background-image: url('../public/images/Banner/arrowEight.svg');
  top: 57%;
  right: 0%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

/* Work Section */

.card-b {
  /* background: #34ABA2; */
  /* background: linear-gradient(45deg, #2b8f89, #34ABA2, #216d67); */
  /* background: linear-gradient(#0C1B44, #09102D); */
  background: linear-gradient(#1a2c66, #131c44);
  /* background: linear-gradient(45deg, #fafafa, #eaeaea, #cccccc, #b3b3b3, #999999); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  transition: transform 430ms;
  height: 265px;
}

.card-b:hover {
  filter: drop-shadow(0px 20px 5px rgba(0, 0, 0, 0.1));
  transform: scale(1.05);
  border: 1px solid #BD24DF;
  /* height: 360px; */
}

.hides {
  display: none;
}

.card-b:hover > .hides {
  display: block;
}

.work-img-bg {
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  top: -51px;
  right: 40%;
}

/* Table Section */

.table-b {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

/* Feature Section */

.feature-font {
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gg {
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
}

/* Simple Section */

.simple-bg {
  background: linear-gradient(90deg, rgba(189, 36, 223, 0.1) 0%, rgba(45, 106, 222, 0.1) 97.15%);
}

.simpleone {
  position: absolute;
  background-image: url('../public/images/Simple/arrowone.svg');
  top: 57%;
  left: 0%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simpletwo {
  position: absolute;
  /* background-image: url('../public/images/Simple/arrowtwo.svg'); */
  top: 15%;
  left: 5%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simplethree {
  position: absolute;
  background-image: url('../public/images/Simple/arrowthree.svg');
  top: 80%;
  left: 8%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simplefour {
  position: absolute;
  background-image: url('../public/images/Simple/arrowfour.svg');
  top: 32%;
  left: 21%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simplefive {
  position: absolute;
  background-image: url('../public/images/Simple/arrowfive.svg');
  top: 84%;
  left: 33%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simplesix {
  position: absolute;
  background-image: url('../public/images/Simple/arrowsix.svg');
  top: 79%;
  left: 63%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simpleseven {
  position: absolute;
  background-image: url('../public/images/Simple/arrowseven.svg');
  top: 47%;
  left: 70%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simpleeight {
  position: absolute;
  background-image: url('../public/images/Simple/arroweight.svg');
  top: 70%;
  left: 78%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simplenine {
  position: absolute;
  background-image: url('../public/images/Simple/arrownine.svg');
  top: 26%;
  left: 82%;
  width: 9%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.simpleten {
  position: absolute;
  background-image: url('../public/images/Simple/arrowten.svg');
  top: 50%;
  right: 0%;
  width: 4%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}



.hover-underline {
  display: inline-flex;
  position: relative;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform .25s ease-out;
}

.hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.space-links {
  transition: .3s;
}

.space-links:hover {
  letter-spacing: 1px;
}

.footer-icons {
  transition: .5s;
}

.footer-icons:hover {
  transform: translateY(-8px);
}

.verticalLine {
  height: 3rem;
  background: #B4C7ED;
  opacity: 0.3;
  width: 2px;
  margin-top: 20px;
}

td:first-child,
th:first-child {
  border-radius: 15px 0 0 15px;
}

td:last-child,
th:last-child {
  border-radius: 0 15px 15px 0;
}

.radial-bgone {
  position: absolute;
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  opacity: 0.5;
  filter: blur(392px);
  border-radius: 508.5px;
  width: 51rem;
  height: 50rem;
  /* right: -13%; */
  top: 50%;
}

.radial-banner {
  position: absolute;
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  opacity: 0.5;
  filter: blur(392px);
  border-radius: 508.5px;
  width: 78rem;
  height: 50rem;
  left: 11%;
  top: -12%;
  z-index: -2;
}

.radial-bg {
  position: absolute;
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  opacity: 1.5;
  filter: blur(392px);
  border-radius: 508.5px;
  width: 51rem;
  height: 25rem;
  left: -383px;
  top: 0%;
  z-index: -1;
}

/* Custom Scrollbar for WebKit Browsers */s
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #ccc;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #370249, #320D3A, #46131F);
  border-radius: 6px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #320D3A #ccc;
}

/* For Firefox, the gradient thumb can be achieved using this additional CSS */
::-moz-scrollbar-thumb {
  background: linear-gradient(90deg, #370249, #320D3A, #46131F);
  border-radius: 6px;
}
